import { defineStore } from 'pinia'
import { db } from '~/service/firebase_config'

import { collection, query, onSnapshot, orderBy, limit, where, doc, updateDoc, setDoc, increment, deleteDoc } from 'firebase/firestore'

import { useEventStore } from './event'
import { mappingTemplateId } from '~/utils/mappingTemplateId'

import _ from 'lodash'
import _cloneDeep from 'lodash/cloneDeep.js'
import _filter from 'lodash/filter.js'
import _shuffle from 'lodash/shuffle.js'
import _map from 'lodash/map.js'
import _difference from 'lodash/difference.js'
import _sample from 'lodash/sample.js'
import _sampleSize from 'lodash/sampleSize.js'
import _find from 'lodash/find.js'
import _first from 'lodash/first.js'
import _last from 'lodash/last.js'
import _take from 'lodash/take.js'
import _tail from 'lodash/tail.js'
import _forEach from 'lodash/forEach.js'
import _intersection from 'lodash/intersection.js'
import _orderBy from 'lodash/orderBy.js'
import { useTemplateStore } from './template'
import dayjs from 'dayjs'

export const useWishStore = defineStore({
  id: 'wish',
  state: () => {
    return {

      index: 0,
      allWishDatabase: [], // wishData
      all: [],  //docIds
      played: [], //docIds
      fetched: [], //docIds
      lastFetchTimestamp: dayjs().valueOf(),  //valueOf()

      newWishes: [], //docIds
      queue: [],
      firstFetch: true,
      existedWishes: {} //docIds
    }
  },
  actions: {
    async main() {
      await this.realtimeFetchWishes()
      this.watchPreFetch()
    },

    shuffle(previousId) {
      let all = _map(this.allWishDatabase, r => r.docId)
      let arr = _shuffle(all)
      //ป้องกัน อันแรก ซำ้จากเดิม
      if (arr.length >= 2 && arr[0] === previousId) {
        // Find another random index to swap with the first item
        const randomIndex = Math.floor(Math.random() * (arr.length - 1)) + 1;
        [arr[0], arr[randomIndex]] = [arr[randomIndex], arr[0]];
      }
      this.queue = arr
    },

    watchPreFetch() {
      const templateStore = useTemplateStore()
      // watch -> queue realtime
      watch(() => this.queue, (newVal: any) => {

        let newDocIds = _take(newVal, 2)
        _forEach(newDocIds, (docId: string) => {
          this.preFetch2(docId)
        })
      }, { deep: true })
    },

    preFetch2(docId: string) {

      let wishData = this.getWishDataById(docId)
      if (this.fetched.includes(docId)) {
        return
      }

      //fetch template images
      let templateStore = useTemplateStore()
      templateStore.preFetchWish(wishData, null)
      let template_data = templateStore.getTemplateData(wishData.templateId)

      //only wish image
      // let url = wishData?.guest?.image?.original ? wishData?.guest?.image?.original?.replace('original', 'present') : `https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/${wishData?.guest?.image?.cfId}/present`
      // if (template_data?.removebg) {
      //   url = wishData?.guest?.image?.remove_bg ? wishData?.guest?.image?.remove_bg.replace('original', 'present') : `https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/${wishData?.guest?.image?.cfRemovebgId}/present`
      // }



      let geustImage = wishData.guest.image.cfId
      if (template_data.elements.placeholder.mode === 'upper') {
        geustImage = wishData.guest.image.cfRemovebgId
      }

      let url2 =  useCloudFlareId(geustImage, 'present')  || wishData.guest.image.present;

      let img = new Image();
      img.onload = () => {
        this.fetched.push(docId)
      }
      img.crossOrigin = "Anonymous"
      img.src = url2

      this.preFetchFont(template_data)
    },
    preFetchFont(template_data) {
      
      let wishFont = template_data?.elements?.wish?.fontFamily
      let nameFont = template_data?.elements?.name?.fontFamily

      //load css font from google font
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = `https://fonts.googleapis.com/css2?family=${wishFont}:wght@400;700&display=swap`
      document.head.appendChild(link)

      const link2 = document.createElement('link')
      link2.rel = 'stylesheet'
      link2.href = `https://fonts.googleapis.com/css2?family=${nameFont}:wght@400;700&display=swap`
      document.head.appendChild(link2)

    },

    addNewWish(docId) {
      // ตรวจสอบว่ามีอยู่แล้วหรือยัง
      if (this.newWishes.includes(docId)) {
        return
      }

      this.newWishes.push(docId)

    },


    realtimeFetchWishes() {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId

      const p = new Promise(resolve => {
        const wishRef = collection(db, `events/${eventId}/wishes`);
        const q = query(wishRef, where('eventId', '==', eventId),
          where('status', 'in', ['approved']), // 'pending']), 
          orderBy('timestamp', 'desc'),
          limit(300))

        const wishDocIdFirstLoad = {}
        onSnapshot(q, querySnapshot => {
          let filteredWish: any = []
          let tmpList = []


          // ตรวจสอบว่า  wish ที่เคยมีแล้ว
          if (this.firstFetch) {

            let tempMap = {}
            querySnapshot.forEach(doc => {
              tempMap[doc.id] = true
            })

            this.existedWishes = tempMap
          }

          querySnapshot.forEach(doc => {
            const data = doc.data()

            // --- filter : wishes version 1 only ----
            if(!data?.version || data?.version !== 'v2') {  
              // คำอวยพรที่ไม่ถูกลบ 
              if(!data?.deleted || data.deleted === false) {

                tmpList.push({
                  ...data,
                  docId: doc.id,
                  templateId: mappingTemplateId(data?.templateId)
                })
  
                if (data.guest?.image && data.wish?.text && data.status == 'approved') {
                  filteredWish.push({
                    ...data,
                    docId: doc.id,
                    templateId: mappingTemplateId(data?.templateId)
                  })
                }
  
                if (this.existedWishes[doc.id] == undefined) {
                  this.existedWishes[doc.id] = true
                  this.addNewWish(doc.id)
                  setTimeout(() => {
                    this.preFetch2(doc.id)
                  }, 100)
  
                }

              }        
            }
          })


          let sorted = _orderBy(filteredWish, 'createAt', 'desc')
          this.allWishDatabase = sorted
          this.all = sorted.map(wish => wish.docId)

          //this.shuffle(null)
          if (this.firstFetch) {
            this.firstFetch = false
            setTimeout(() => {
              this.shuffle(null)
            }, 2000);
          }

          console.log('fetched: wishes')
          resolve(true)
        })

      })

      return p
    },

    async updatePlayWish(wishData) {

      const eventStore = useEventStore()
      const docId = wishData.docId
      const eventId = eventStore.eventId

      // update played
      if (!wishData.played) {
        updateDoc(doc(db, `events/${eventId}/wishes/${docId}`), {
          played: true,
        })
      }

    },

    async updateStatusWish(docId, status) {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId

      await updateDoc(doc(db, `events/${eventId}/wishes/${docId}`), {
        status: status,
      })
    },

    getNewDocIdList() {
      return this.newWishes
    },

    getWishDataById(docId: string) {
      return _find(this.allWishDatabase, (r) => r.docId == docId);
    }

  },
  getters: {}
})
